import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import './desktop.css';
import './mobile.css';
import './tablet.css';
import Galery from './pages/Galery';
import Homepage from './pages/Homepage';
import Legals from './pages/Legals';
import Rates from './pages/Rates';
import Contact from './pages/Contact';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="tarifs" element={<Rates />} />
        <Route path="galerie" element={<Galery />} />
        <Route path="contact" element={<Contact />} />
        <Route path="mentions" element={<Legals />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
