import { useState } from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useMediaQuery from '../hooks/useMediaQuery';
import useMeta from '../hooks/useMeta';
import Hero from '../organisms/Hero';
import News from '../organisms/News';
import Page from '../templates/Page';

const Homepage = () => {
  const colors = ['orange', 'bleu', 'vert'];
  const [activeIndex, setActiveIndex] = useState(null);
  const [content, setContent] = useState(null);
  const isTablet = useMediaQuery('(min-width: 650px) and (max-width: 979px)');
  const isDesktop = useMediaQuery('(min-width: 980px)');

  useDocumentTitle('Accueil');
  useMeta('description', 'Smileball, terrain de paintball à Argentan dans l\'Orne, propose la location de matériel, des bigs games, parties scénarisées et tournois.');
  useMeta('keywords', 'paintball, smileball, location paintball, tournoi paintball, Argentan, forêt, lanceur');

  return (
    <Page>
      <Hero />
      <div id="news">
        <News color="orange" img="1.jpg" title="Pour les enfants" isOpened={!isTablet && activeIndex === 1} badge="Nouveau" onShow={(children) => {
          if (!isTablet) {
            setActiveIndex(activeIndex === 1 ? null : 1);
            setContent(activeIndex === 1 ? null : children);
          }
        }}>
          <p>
            <strong>Nouveauté : </strong> Découvrez les nouveaux lanceurs Gellyball, accessible dès 6 ans, ils tirent des billes de gel qui ne tâchent pas ! Idéal pour une sortie en famille, grâçe à leur faible impact.
          </p>
          <p>
            Pour les enfants de 8 à 12 ans nous proposons une découverte du paintball avec du matériel adapté à leur âge et leur morphologie. Les lanceurs "SPLATMASTER" d'un poids de 500g fonctionnent avec un mécanisme à ressort.
            Ils tirent de petites billes de peintures d'une vélocité moins forte que les vrai lanceurs de paintball.
          </p>
          <p>6 enfants minimum, mercredi aprés midi, tous les jours pendant les vacances scolaires.</p>
          <p>Pour toute réservation par téléphone au <strong>06 26 11 37 25</strong>.</p>
        </News>
        <News color="bleu" img="2.gif" title="Paintball loisir" isOpened={!isTablet && activeIndex === 2} onShow={(children) => {
          if (!isTablet) {
            setActiveIndex(activeIndex === 2 ? null : 2);
            setContent(activeIndex === 2 ? null : children);
          }
        }}>
          <p>Smileball organise votre journée de loisir pour C.E, enterrement de vie de garçon, anniversaire.</p>
          <p>Vous pourrez vous plonger dans le jeu avec de nombreux scénarios sur ses 5 terrains étudiés pour le jeu grandeur nature. Avec un "Spécial chasse au lapin" pour les enterrements de vie de garçon...</p>
          <p>Pour toute réservation par téléphone au <strong>06 26 11 37 25</strong>.</p>
        </News>
        <News color="vert" img="3.png" title="Bubble foot" isOpened={!isTablet && activeIndex === 3} onShow={(children) => {
          if (!isTablet) {
            setActiveIndex(activeIndex === 3 ? null : 3);
            setContent(activeIndex === 3 ? null : children);
          }
        }}>
          <p>À partir d'1m50.</p>
          <p>Pour toute réservation par téléphone au <strong>06 26 11 37 25</strong>.</p>
        </News>
      </div>
      {isDesktop && <div id="text" className={activeIndex !== null ? `opened border-${colors[activeIndex - 1]}` : ''}>{content}</div>}
    </Page>
  );
}

export default Homepage;
