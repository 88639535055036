const News = ({ color, img, title, onShow, isOpened = false, badge = null, children }) => {
  return (
    <article onClick={() => onShow(children)} className={isOpened ? 'opened' : ''}>
      {badge !== null && <span className="ribbon"><span>{badge}</span></span>}
      <img src={`/news/${img}`} alt={title} />
      <h2 className={color}>{title}</h2>
      <div className={`border-${color}`}>{children}</div>
    </article>
  )
}

export default News
