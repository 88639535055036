import useDocumentTitle from '../hooks/useDocumentTitle';
import useMeta from '../hooks/useMeta';
import Rating from '../organisms/Rating';
import Page from '../templates/Page';

const Rates = () => {
  useDocumentTitle('Tarifs');
  useMeta('description', 'Tarifs pour la location de matériel de paintball ou du terrain.');
  useMeta('keywords', 'paintball, smileball, location paintball, Argentan, tarifs, lanceur');

  const ratings = [
    {
      title: '200 billes',
      price: 20,
      includes: [
        'Masque de protection',
        'Lanceur de calibre 68',
        'Protège cou',
        'Plastron',
      ],
    },
    {
      title: '500 billes',
      price: 35,
      includes: [
        'Masque de protection',
        'Lanceur de calibre 68',
        'Protège cou',
        'Plastron',
      ],
    },
    {
      title: 'Joueurs équipés',
      price: 10,
      includes: [
        'Air illimité',
        'Billes sur site',
      ],
    },
    {
      title: 'Joueurs équipés',
      price: 15,
      includes: [
        'Air illimité',
        'Billes libres',
      ],
    },
    {
      title: 'Airsoft',
      price: null,
      includes: [
        'Location du terrain',
      ],
    },
    {
      title: 'Mixte 1h Paintball',
      price: 25,
      includes: [
        'Paintball 150 billes',
        'Sumo gonflable',
      ],
    },
    {
      title: 'Mixte 1h Bubble foot',
      price: 25,
      includes: [
        'Bubble foot',
        'Sumo gonflable',
      ],
    },
    {
      title: 'Mixte 2h',
      price: 35,
      includes: [
        'Paintball 150 billes',
        'Bubble foot',
        'Sumo gonflable',
      ],
    },
    {
      title: 'Splatmaster',
      price: 20,
      includes: [
        '100 billes',
        'Masque de protection',
        'Protège cou',
        'Plastron',
      ],
    },
    {
      title: 'Gellyball',
      price: 15,
      includes: [
        'https://fb.watch/j-DShUOF-2/',
        '1400 billes',
        'Masque de protection',
      ],
    },
  ];

  const baseColors = ["orange", "bleu", "vert", "jaune", "rouge"];
  const colors = [...baseColors, ...baseColors];
  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    };
  }
  shuffleArray(colors);

  return (
    <Page>
      <div id="tarifs" className="grid">
        {ratings.map(({ title, price, includes }, i) => <Rating title={title} price={price} includes={includes} color={colors[i]} />)}
      </div>
    </Page>
  );
}

export default Rates;
