import { useState, useEffect } from 'react';

const useMediaQuery = query => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaWatcher = window
      .matchMedia(query);

    setMatches(mediaWatcher.matches);

    const updateMedia = (e) => {
      setMatches(e.matches);
    };

    mediaWatcher.addEventListener('change', updateMedia);

    return () => {
      mediaWatcher.removeEventListener('change', updateMedia);
    }
  }, [query, setMatches]);

  return matches;
}

export default useMediaQuery;
