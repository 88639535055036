import { Link } from "react-router-dom"

const Hero = () => (
  <div id="hero">
    <img src="/slide.jpeg" alt="" />
    <div className="message">
      <h3 className="jaune">Composez votre journée</h3><br />
      <Link to="/tarifs">voir la page Tarifs</Link>
    </div>
  </div>
)

export default Hero
