import useDocumentTitle from '../hooks/useDocumentTitle';
import useMeta from '../hooks/useMeta';
import Page from '../templates/Page';

const Rates = () => {
  useDocumentTitle('Galerie');
  useMeta('description', 'Tous les albums photos des événements en tous genres auxquels Smileball a participé.');
  useMeta('keywords', 'paintball, smileball, location paintball, Argentan, photos, albums, lanceur');

  return (
    <Page>
      <div id="galeries">
        <a href="https://e.pcloud.link/publink/show?code=kZiH3SZSpf7TF54X6hia2hyhnQ2CmSBtvFk" rel="noreferrer" target="_blank">Consultez nos différents albums ici.</a>
      </div>
    </Page >
  );
}

export default Rates;
