import { Link } from "react-router-dom"

const Rating = ({ title, price, includes, color }) => {
  return (
    <article className={price === null ? 'no-price' : ''}>
      <header className={color}>
        <h3>{title}</h3>
      </header>
      <div>
        <p>Inclus :</p>
        <ul>
          {includes.map(name => {
            const content = name.startsWith('https://') ? (<a href={name} rel="noreferrer" target="_blank">Vidéo de présentation</a>) : name;

            return (
              <li><span className={`text-${color}`}>&#47;&#47;</span> {content}</li>
            )
          })}
        </ul>
      </div>
      <h2 className={color}>{price === null ? <Link to="/contact">Nous contacter</Link> : `PRIX TOTAL : ${price}€/pers`}</h2>
    </article>
  )
}

export default Rating
