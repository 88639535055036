import { Link } from "react-router-dom"

const Footer = () => (
  <footer>
    <p>
      <Link to="/mentions">Mentions légales</Link> &mdash; Création Thomas Triboult et <a href="http://chloe-allard.fr" target="_blank" rel="noreferrer">Chloé Allard</a>
    </p>
  </footer>
)

export default Footer
