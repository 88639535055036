import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Footer from "../organisms/Footer";
import Header from "../organisms/Header";

const Page = ({ children }) => {
  const [showToTop, setShowToTop] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setShowToTop(window.scrollY > 400);
    });
  });

  const handleClickToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeZS4UlAAAAAKgqiQxIY0Tm9KL4DEFmekT2j5_t" container={{ parameters: { badge: 'bottomleft' } }}>
      {showToTop && (<div title="Retour en haut de page" id="return" onClick={handleClickToTop} />)}
      <Header />
      <div id="content">{children}</div>
      <Footer />
    </GoogleReCaptchaProvider >
  )
}

export default Page
