import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useMeta from '../hooks/useMeta';
import ContactForm from '../organisms/ContactForm';
import Page from '../templates/Page';

const Contact = () => {
  useDocumentTitle('Contact');
  useMeta('description', 'Pour toutes demandes concernant les tarifs ou la réservation n\'hésitez pas à nous contacter.');
  useMeta('keywords', 'paintball, smileball, location paintball, Argentan, contact, réservation, tarifs');

  const { isLoaded } = useJsApiLoader({
    'id': 'google-map-script',
    googleMapsApiKey: 'AIzaSyCP7AJNXx-H1MUk05u5ms9BZE2PMH6oBHw',
  })
  const mapCenter = { lat: 48.718116, lng: -0.004513 }

  return (
    <Page>
      <div id="contacts">
        <ContactForm />
        <div id="infos" itemscope itemtype="http://schema.org/Organization">
          <div id="tabletBox">
            <p id="adresse" className="text-rouge" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
              <span itemprop="name" className="phoneOnly text">Smileball<br /></span>
              <span itemprop="streetaddress">rue de la Forge</span><br />
              <span itemprop="postalCode">61200</span> <span itemprop="addresslocality">Saint-Martin-des-champs</span>
            </p>
            <p id="gps" itemprop="location" itemscope itemtype="http://schema.org/Place">
              <a href="http://maps.apple.com/?daddr=48.718116,-0.004513" className="text-bleu">&gt; Lancer le GPS sur iPhone</a><br />
              <a href="google.navigation:q=48.718116,-0.004513" className="text-bleu">&gt; Lancer le GPS sur un appareil Android</a><br />
              <span itemprop="geo" itemscope itemtype="http://schema.org/GeoCoordinates">Coordonnées pour GPS : <span itemprop="latitude">48.718116</span>, <span itemprop="longitude">-0.004513</span></span>
            </p>
            <p id="contact">
              <span className="text-rouge">Tel //</span> 06 26 11 37 25 <span className="text-rouge">-</span> 02 33 67 12 91<br />
              <span className="text-rouge">Mail //</span> contact@smileball.fr
            </p>
          </div>
          <div id="map">
            {isLoaded && (
              <GoogleMap center={mapCenter} zoom={12} mapContainerStyle={{ height: '100%' }}>
                <MarkerF position={mapCenter} title="Smileball" />
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
    </Page >
  );
}

export default Contact;
