import { useEffect } from "react";

const useMeta = (type, value) => {
  useEffect(() => {
    document
      .querySelector(`meta[name="${type}"]`)
      .setAttribute('content', value);
  })
}

export default useMeta;
