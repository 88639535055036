import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import FormInput from "../molecules/FormInput";

const ContactForm = () => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validation = {
    name: value => value.length > 1,
    email: value => value.length > 1 && value.includes('@'),
    message: value => value.length > 1,
  };

  useEffect(() => {
    let formIsValid = true;

    if (Object.entries(errors).length > 0) {
      formIsValid = false;
    }

    setIsValid(formIsValid);
  }, [errors]);

  const onFieldChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });

    if (validation[name] !== null && validation[name](value) === false) {
      setFieldError(name, 'Cette valeur n\'est pas correcte.');
    } else {
      setErrors({
        ...Object.keys(errors)
          .filter(key => key !== name)
          .reduce((acc, key) => {
            acc[key] = errors[key];

            return acc;
          }, {})
      })
    }
  }

  const setFieldError = (name, message) => {
    setErrors({
      ...errors,
      [name]: message,
    });
  }

  const getRecaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    return await executeRecaptcha('contact_submit');
  }, [executeRecaptcha]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setGlobalError(null);

    try {
      await axios.post('/contact-form', {
        ...values,
        token: await getRecaptchaToken()
      });

      setValues({
        name: '',
        email: '',
        message: ''
      });
    } catch (error) {
      setGlobalError('Impossible d\'envoyer votre message.');
    }
  }

  return (
    <form id="contactForm" onSubmit={handleFormSubmit}>
      <FormInput name="name" placeholder="Nom" error={errors.name} onChange={onFieldChange} />
      <FormInput name="email" type="email" placeholder="E-mail" error={errors.email} onChange={onFieldChange} />
      <FormInput name="message" type="textarea" placeholder="Votre message" error={errors.message} onChange={onFieldChange} />
      {globalError !== null && <div className="field has-error"><span className="error">{globalError}</span></div>}
      <div className="field-centered">
        <input type="submit" value="Envoyer" disabled={!isValid} />
      </div>
    </form>
  )
}

export default ContactForm;
