import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "../hooks/useMediaQuery";

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);
  const isPhone = useMediaQuery('(max-width: 649px)');
  const currentPage = useLocation().pathname;

  const handlePhoneMenuClick = () => {
    if (isPhone) {
      setIsOpened(!isOpened);
    }
  }

  return (
    <header>
      <Link to="/" className="logo">
        <img src="/logo.png" alt="Logo Smileball" />
      </Link>
      <button id="phoneMenu" className={(isPhone && isOpened) ? 'opened' : ''} onClick={handlePhoneMenuClick}>
        <svg width="70" height="70" viewBox="0 0 100 100">
          <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
          <path class="line line2" d="M 20,50 H 80" />
          <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
        </svg>
      </button>
      <div className="phoneOnly">&nbsp;</div>
      <nav className={(isPhone && isOpened) ? 'opened' : ''}>
        <ul>
          <li className={currentPage === '/' ? 'active' : ''}>
            <Link to="/">Accueil<span className="cursor jaune"></span></Link>
          </li>
          <li className={currentPage === '/galerie' ? 'active' : ''}>
            <Link to="/galerie">Galerie<span className="cursor vert"></span></Link>
          </li>
          <li className={currentPage === '/tarifs' ? 'active' : ''}>
            <Link to="/tarifs">Tarifs<span className="cursor bleu"></span></Link>
          </li>
          <li className={currentPage === '/contact' ? 'active' : ''}>
            <Link to="/contact">Contact<span className="cursor rouge"></span></Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
