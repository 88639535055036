const FormInput = ({ type = 'text', name, placeholder, value, onChange, error = null }) => {
  const handleOnChange = event => {
    onChange(name, event.target.value);
  };

  return (
    <div className={`field ${error !== null ? 'has-error' : ''}`}>
      {type !== 'textarea' ? (
        <input type={type} value={value} name={name} onChange={handleOnChange} placeholder={placeholder} />
      ) : (
        <textarea value={value} name={name} onChange={handleOnChange} placeholder={placeholder} />
      )}
      {error !== null && <span className="error">{error}</span>}
    </div>
  )
}

export default FormInput;
